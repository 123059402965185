<template>
  <div>
    <b-row>
      <b-col id="order-detail" v-if="orderSelected">
        <OrderDetail
          v-if="orderSelected"
          :orderSelected="orderSelected"
          :getOrderInformationById="getOrderInformationById"
          :isMobile="isMobile"
        />
      </b-col>
      <b-col id="order-user-detail" class="" v-if="orderSelected && !isMobile">
        <OrderUserDetail
          :orderSelected="orderSelected"
          :getOrderInformationById="getOrderInformationById"
          :isMobile="isMobile"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrderDetail from "@/components/order/OrderDetail";
import OrderUserDetail from "@/components/order/OrderUserDetail";
export default {
  components: {
    OrderDetail,
    OrderUserDetail
  },
  data() {
    return {
      orderSelected: "",
      orderId: 0,
      isMobile: this.$isMobile
    };
  },
  created: async function() {
    this.screenChange();
    this.orderId = this.$route.params.id;
    this.$store.dispatch("setIsLoading", true);
    if (parseInt(this.orderId) !== 0) {
      //this.onsetOrderSelected();
      await this.getOrderInformationById(this.$route.params.id);
    } else {
      this.onsetOrderSelected();
    }
    window.addEventListener("resize", this.screenChange);
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      brandID: "getBrand",
      isStore: "getStoreFlag",
      isShowModalOverLay: "getIsLoading",
      mainColor: "getBrandColor",
      isReload: "getIsReload"
    })
  },
  watch: {
    isReload: function(val) {
      if (this.$route.params.id && parseInt(this.$route.params.id) !== 0) {
        if (val) {
          this.getOrderInformationById(this.$route.params.id);
        }
      }
    }
  },
  methods: {
    screenChange() {
      if (window.innerWidth <= 1199) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    onsetOrderSelected() {
      this.orderSelected = {
        branchId: "",
        brandId: this.brandID,
        canDownloadJobSheet: false,
        canUpdateTracking: false,
        checkSum: "",
        countFreeProduct: 0,
        discount: 0,
        discountPromotion: 0,
        expiredDate: "",
        freeProducts: [],
        grandTotal: 0,
        id: 0,
        invoiceNo: "",
        isFreeProduct: false,
        note: "",
        paymentMethodId: 0,
        paymentName: "-",
        paymentReference: "",
        productDiscount: [],
        products: [],
        promotions: [],
        reasonId: 0,
        reasonNote: "",
        replyReferenceId: "",
        replyTypeId: 0,
        saleChannel: "",
        shippingCost: 0,
        shippingTypeId: 0,
        shippingTypeName: "",
        socialRef: {},
        statusId: 1,
        statusName: "สั่งซื้อ",
        total: 0,
        totalQuantity: 0,
        trackingNo: {},
        userGUID: "",
        weightTotal: 0,
        buyerInfo: {
          userInfo: {
            telephone: "",
            firstname: "",
            lastname: "",
            email: "",
            socialMediaId: 0,
            socialMediaName: "",
            socialReferenceId: ""
          },
          shippingAddress: {
            firstname: "",
            lastname: "",
            address: "",
            road: "",
            province: "",
            district: "",
            subdistrict: "",
            zipcode: ""
          },
          billingAddress: {
            firstname: "",
            lastname: "",
            address: "",
            road: "",
            province: "",
            district: "",
            subdistrict: "",
            zipcode: ""
          },
          sameAddress: false,
          shippingAddressId: 0,
          billingAddressId: 0,
          isConsent: false,
          isRequestTax: false,
          promoCode: "",
          promoCodeMessage: ""
        }
      };

      this.$store.dispatch("setIsLoading", false);
    },
    getOrderInformationById: async function(orderId) {
      //this.$store.dispatch("setIsLoading", true);
      await this.$axios
        .get(this.$baseUrl + "/api/transaction/" + orderId)
        .then(response => {
          if (response.data.result === 1) {
            if (!response.data.detail.buyerInfo.shippingAddress) {
              response.data.detail.buyerInfo = {
                userInfo: {
                  telephone: "",
                  firstname: "",
                  lastname: "",
                  email: "",
                  socialMediaId: 0,
                  socialMediaName: "",
                  socialReferenceId: ""
                },
                shippingAddress: {
                  firstname: "",
                  lastname: "",
                  address: "",
                  road: "",
                  province: "",
                  district: "",
                  subdistrict: "",
                  zipcode: ""
                },
                billingAddress: {
                  firstname: "",
                  lastname: "",
                  address: "",
                  road: "",
                  province: "",
                  district: "",
                  subdistrict: "",
                  zipcode: ""
                },
                sameAddress: false,
                shippingAddressId: 0,
                billingAddressId: 0,
                isConsent: false,
                isRequestTax: false
              };
            }
            this.orderSelected = response.data.detail;
          } else {
            console.error("Something went wrong");
          }
          this.$store.dispatch("setIsReload", false);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    getOrderTransFerSlipById(orderId) {
      this.$axios
        .get(this.$baseUrl + "/api/transaction/" + orderId + "/transferSlip")
        .then(response => {
          if (response.data.result === 1) {
            this.orderTransferSlip = response.data.detail;
          } else {
            alert(response.data.message);
          }
        });
    },
    getBankAccountByBrandId(id) {
      // console.log("test");
      this.$axios
        .get(this.$baseUrl + "/api/payment/bankaccount", {
          params: { transactionId: id }
        })
        .then(response => {
          if (response.data.result === 1) {
            this.bankAccountLists = response.data.detail;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 1200px) {
  #order-detail {
    padding-left: 0px !important;
    padding-right: 0px !important;
    min-height: calc(100vh - 50px);
  }

  #order-user-detail {
    padding-left: 0px !important;
    padding-right: 0px !important;
    border-left: solid 5px black;
    min-height: calc(100vh - 55px);
  }
  .order-user-detail-content {
    height: calc(100vh - 50px);
  }
}
</style>
